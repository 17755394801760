import React from 'react';
import { Container, Title, Text, Button, Paper } from '@mantine/core';
import { motion } from 'framer-motion';
import classes from './MaintenanceHero.module.css';
import ContactForm from './ContactForm';

export default function MaintenanceHero() {
  const scrollToContact = () => {
    const formWrapper = document.querySelector('#contact-form');
    if (formWrapper) {
      formWrapper.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainContainer}>
        <div className={classes.inner}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className={classes.content}
            key="content"
          >
            <Title className={classes.title}>
              <span className={classes.highlight}>Evolving Minds</span>
            </Title>
            
            <Text className={classes.tagline}>
              Where change happens
            </Text>
            
            <Text className={classes.description}>
              We're working hard to improve our website and we'll ready to launch soon.
            </Text>

            <Text className={classes.description}>
              Phone: <a href="tel:07306665557" className={classes.contactLink}>07306665557</a>
            </Text>
            <Text className={classes.description}>
              Email: <a href="mailto:info@emhub.org.uk" className={classes.contactLink}>info@emhub.org.uk</a>
            </Text>

            <motion.button
            className={classes.ctaButton}
            onClick={scrollToContact}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Contact Us
          </motion.button>

          <Text className={classes.description} style={{ display: 'none' }}>
              We're launching soon! Be the first to know.
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className={classes.formWrapper}
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            id="contact-form"
          >
            <ContactForm />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
