import React, { useEffect } from 'react';
import { initGA, trackPageView } from './services/analytics';
import { MantineProvider, createTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import MaintenanceHero from './components/MaintenanceHero';

// Import Mantine styles
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

const theme = createTheme({
  primaryColor: 'blue',
  colors: {
    // You can customize your color palette here
  },
});

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    initGA();
    
    // Track initial page view
    trackPageView(window.location.pathname + window.location.search);

    // Track page views on route changes (when you add routing)
    const handleRouteChange = (url) => {
      trackPageView(url);
    };

    // Add route change listener when you implement routing
    // Example with react-router:
    // history.listen(({ pathname, search }) => handleRouteChange(pathname + search));

    return () => {
      // Clean up listeners when you implement routing
    };
  }, []);
  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-right" />
      <MaintenanceHero />
    </MantineProvider>
  );
}

export default App;
