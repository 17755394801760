export async function sendContactEmail(data) {
  const { name, email, phone, message } = data;
  
  try {
    // Always use the relative API endpoint which will be handled by the proxy in development
    // or the actual server in production
    const apiUrl = '/api/email/contact';
    
    console.log('Sending contact form submission');
    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
        formId: 'website-contact',
        source: 'website',
        sourceDetails: {
          page: window.location.pathname
        }
      })
    });

    // Log the response status for debugging
    console.log(`Contact form API response status: ${response.status}`);

    if (!response.ok) {
      let errorMessage = 'Failed to send email';
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorData.error || errorMessage;
      } catch (jsonError) {
        // If response is not JSON, try to get text
        try {
          const textError = await response.text();
          if (textError) errorMessage = textError;
        } catch (textError) {
          // If we can't get text either, just use the status
          errorMessage = `Server error: ${response.status}`;
        }
      }
      throw new Error(errorMessage);
    }

    const result = await response.json();
    console.log('Contact form submission successful');
    return result;
  } catch (error) {
    console.error('Error sending contact form:', error);
    throw error;
  }
}
