import React, { useState } from 'react';
import { trackFormSubmission, trackError } from '../services/analytics';
import { sendContactEmail } from '../api/contact';
import { TextInput, Textarea, Button, Paper, Title } from '@mantine/core';
import { motion } from 'framer-motion';
import { notifications } from '@mantine/notifications';
import classes from './ContactForm.module.css';

export default function ContactForm() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      message: formData.get('message')
    };

    try {
      await sendContactEmail(data);

      // Track successful form submission
      trackFormSubmission('contact', true);

      notifications.show({
        title: 'Message Sent',
        message: 'Thanks for reaching out! We\'ll get back to you soon.',
        color: 'green',
      });
      event.target.reset();
    } catch (error) {
      // Track form error
      trackError('ContactForm', error.message);
      trackFormSubmission('contact', false);

      notifications.show({
        title: 'Error',
        message: 'Something went wrong. Please try again.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className={classes.formContainer}
    >
      <div className={classes.form}>
        <Title order={2} className={classes.title} styles={{ root: { fontSize: '2.5rem', fontWeight: 700, marginBottom: '2rem' } }}>
          Contact Us
        </Title>

        <form onSubmit={handleSubmit} className={classes.formElements}>
          <TextInput
            label="Name"
            placeholder="Your name"
            name="name"
            required
            classNames={{
              root: classes.inputRoot,
              input: classes.input,
              label: classes.label,
              required: classes.required
            }}
            size="lg"
          />
          <TextInput
            label="Email"
            placeholder="your@email.com"
            name="email"
            required
            classNames={{
              root: classes.inputRoot,
              input: classes.input,
              label: classes.label,
              required: classes.required
            }}
            size="lg"
            mt="lg"
          />
          <TextInput
            label="Phone"
            placeholder="Your phone number"
            name="phone"
            classNames={{
              root: classes.inputRoot,
              input: classes.input,
              label: classes.label,
              required: classes.required
            }}
            size="lg"
            mt="lg"
          />
          <Textarea
            label="Message"
            placeholder="How can we help you?"
            name="message"
            required
            minRows={6}
            classNames={{
              root: classes.inputRoot,
              input: classes.textarea,
              label: classes.label,
              required: classes.required
            }}
            size="lg"
            mt="lg"
          />

          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              type="submit"
              size="lg"
              fullWidth
              mt="xl"
              loading={loading}
              className={classes.animatedButton}
            >
              Send Message
            </Button>
          </motion.div>
        </form>
      </div>
    </motion.div>
  );
}
