import ReactGA from 'react-ga4';

export const GA_TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const initGA = () => {
  if (GA_TRACKING_ID) {
    ReactGA.initialize(GA_TRACKING_ID);
  }
};

// Track page views
export const trackPageView = (path) => {
  if (!GA_TRACKING_ID) return;
  
  ReactGA.send({
    hitType: 'pageview',
    page: path,
  });
};

// Track events
export const trackEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction = false,
}) => {
  if (!GA_TRACKING_ID) return;

  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
  });
};

// Track user interactions
export const trackInteraction = (action, label = '', value = null) => {
  trackEvent({
    category: 'User Interaction',
    action,
    label,
    value,
  });
};

// Track form submissions
export const trackFormSubmission = (formName, success = true) => {
  trackEvent({
    category: 'Form',
    action: success ? 'Submit Success' : 'Submit Error',
    label: formName,
  });
};

// Track navigation
export const trackNavigation = (from, to) => {
  trackEvent({
    category: 'Navigation',
    action: 'Page Change',
    label: `${from} → ${to}`,
  });
};

// Track document downloads
export const trackDownload = (documentName, documentType) => {
  trackEvent({
    category: 'Download',
    action: documentType,
    label: documentName,
  });
};

// Track external links
export const trackExternalLink = (url) => {
  trackEvent({
    category: 'External Link',
    action: 'Click',
    label: url,
  });
};

// Track search
export const trackSearch = (searchTerm, resultsCount) => {
  trackEvent({
    category: 'Search',
    action: 'Query',
    label: searchTerm,
    value: resultsCount,
  });
};

// Track error events
export const trackError = (errorType, errorMessage) => {
  trackEvent({
    category: 'Error',
    action: errorType,
    label: errorMessage,
    nonInteraction: true,
  });
};
